/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;900&display=swap');
@import "~@angular/material/prebuilt-themes/pink-bluegrey.css";
@import '~@angular/cdk/overlay-prebuilt.css';


.partnersImage {
    /*border-bottom: 1px solid white;*/
    margin-left: 15px;
    margin-top: -35px;
    margin-bottom: -15px;
    max-width: 700px;
}

.aboutDescription {
    margin-left: 15px;
    font-size: 14px;
}

.touchDescription {
    margin-bottom: -10px;
}
.touchLink {
    color: #ec008c !important
}

.socialRow {
    margin-top: 30px;
    margin-left: 10px;
}

.copyrightRow {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

gallery {
    border: solid 5px #ec008c !important;
    margin-bottom: 30px;
}

.youtubeContainer iframe {
    margin: 0 auto !important;
    border: solid 5px #ec008c !important;
    text-align: center !important;
}

.youtubeContainerMobile iframe {
    margin: 0 auto !important;
    border: solid 5px #ec008c !important;
    text-align: center !important;
}
.youtubeContainerMobile {
    padding-top: 30px;
}

.youtubeContainer {
    margin: 0 auto !important;
    text-align: center !important;
    padding-top: 70px;
}

.signupButton {
    --background: white;
    --color: black;
    margin-top: 35px;
}

.signupContainer {
    margin: 0 auto;
    filter: drop-shadow(0px -1px 4px rgba(0, 0, 0, 0.5));
    z-index: 10;
    position: relative;
    padding: 15px 25px 35px 25px;
    background-color: #ec008c !important
}

.signupContainer2 {
    margin: 0 auto;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
    z-index: 6;
    position: relative;
    padding: 15px 25px 35px 25px;
    background-color: #ec008c !important
}

.signupItem {
    --padding-start: 0px !important;
    margin-left: 50px !important;
    --border-color: #FFF;
    --color-activated: #fff !important;
    --color-focused: #fff !important;
    --ripple-color: transparent;
    &.item-has-focus ion-label {
        color: white !important;
    }
}

.signupTitle {
    font-family: "Raleway";
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    margin-bottom: -10px;
}

.signupDescription {
    text-align: left;
}

.footerBrand {
    max-width: 300px;
}

.signupRow {
    
    max-width: 1400px;
    margin: 0 auto;
}

.creditsRow {
    filter: drop-shadow(0px -1px 4px rgba(0, 0, 0, 0.5));
    z-index: 11;
    position: relative;
    padding: 50px;
}

.mat-sidenav-container, .mat-sidenav-content, .mat-tab-body-content {
    transform: none !important;
}

.link {
    color: white !important;
    text-decoration: underline !important;
    font-weight: bold !important;
}

.alert-radio-label.sc-ion-alert-md {
    text-transform: capitalize !important;
}

.alert-radio-label.sc-ion-alert-ios {
    text-transform: capitalize !important;
}

ion-select.programSelect::part(label) {    
    text-transform: capitalize !important;
 }

 ion-select.programSelect::part(text) {
    text-transform: capitalize !important;
}

.carousel-indicators {
    display: none;
}

:root .blogContainer {
  --swiper-navigation-size: 44px;
  --swiper-navigation-color: white;
  --swiper-navigation-sides-offset: 50px;
}



@media all and (min-width: 1025px){

    .carousel-inner {
        padding: 0px 150px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .carousel-control-next-icon, .carousel-control-prev-icon {
        background-color: black;
        border-radius: 50px;
        padding: 20px;
        background-size: 50% 50%;
    }

    .gridTitle {
        font-family: 'Raleway', sans-serif;
        color: #000;
        font-size: 5vw;
        line-height: 1em;
        text-align: center;
        padding: 20px 0 20px 0;
        font-weight: 900 !important;
    }

    .slideContent {
        /*margin-left: 40px;*/
        margin-left: 0px;
    }

    ion-slide {
        min-height: 900px !important;
    }

    .sectionContainer swiper-slide {
        min-height: 900px !important;
    }

    .sectionSubtitle {
        font-family: Raleway;
        /*font-weight: 900;*/
        color: #fff;
        /*font-size: 2vw;*/
        font-size: 1.6vw;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .titleType {
        font-family: Raleway;
        font-size: 18px;
        font-weight: 900;
    }

    ion-modal.authModal::part(content) {    
        --height: 90%;
        position: absolute; 
        top: 5%;
        --width: 100%;
        border-radius: 25px;
        display: block;
     }

    .newsContent {
        font-size: 20px;
     }

    .desktopMenuGrid {
        max-width: 100%;
        margin-top: 25px;
        margin-right: 20px;
    }
    .desktopMenuItem {
        font-family: Raleway;
        /*font-weight: 900;*/
        text-transform: uppercase;
        margin-left: 10px;
        text-shadow: 3px 3px rgba(0,0,0,.2);
        cursor: pointer;
    }

    .desktopMenuItem span {
        word-wrap: normal;
        white-space: nowrap;
    }

    .desktopMenuItem.active-menu {
        border: 1px solid white;
        margin-top: -2px !important;
    }
    .desktopAccountButton {
        margin-top: 25px !important;
        margin-left: 20px !important;
        border: 2px solid white !important;
    }
    .socials {
        max-width: 500px;
        float: right;
    }
    .socialIcon {
        margin-right: 25px;
    }
    .headerActionButton {
        float: right;
        margin-top: -45px;
    }
    ion-toolbar.mainToolbar {
        height: 100px;
        --background: #000000 !important;
        /*--background: linear-gradient(155deg, rgba(25,0,102,1) 0%, rgba(255,0,106,1) 100%) !important;*/
        filter: drop-shadow(0px 1px 4px rgb(0, 0, 0, 0.5));
    }
    
    ion-title.mainTitle {
        padding-top: 35px;
        padding-left: 35px;
    }
    
    .headerButtons {
        padding-right: 15px;
    }
    
    .headerButton {
        padding-top: 25px;
    }

    .audioClose {
        position: absolute;
        right: 18px;
        bottom: 23px;
    }
    .mat-slider-wrapper {
        background-color: #737373 !important;
    }
    
    .mat-slider-track-fill {
        background-color: rgb(255, 0, 106) !important
    }
    
    .mat-button {
        min-width: 1px !important;
        background-color: transparent !important;
    }
    
    .mat-slider-thumb {
        background-color: white !important;
        border: solid 3px white !important;
    }
    
    .mat-focus-indicator.ngx-basic-audio-player-button-handler.mat-button.mat-button-base {
        font-size: 14px !important;
    }
    
    .mat-card>:last-child:not(.mat-card-footer) {
        margin-right: 25px;
    }
}


.pressMobile {
    margin-bottom: -30px;
}

.vipItem {
    font-size: 14px !important;
    margin-left: 10px;
}

.vipIcon {
    font-size: 12px !important;
}

.buttonText {
    margin-left: 10px;
}

.desktopGrid {
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.pressCard {
    width: calc((100% + 15px) / 4);
    background-color: black;
}

.desktopCard {
    width: calc((100% + 15px) / 5);
    background-color: black;
}

.desktopCardAlt {
    width: calc((100% + 15px) / 6);
    background-color: black;
}

.desktopCardAlt2 {
    width: calc((100% + 15px) / 4);
    background-color: black;
}

.desktopBrand {
    width: calc((100% + 15px) / 6);
    padding: 5px;
}

.gridButton {
    --background: transparent;
    --background-hover: transparent;
}

.cardToolbar {
	z-index: 0;
	border-bottom-left-radius: 8px !important;
	border-bottom-right-radius: 8px !important;
	padding: 20px 0px 0px 0px;
}

.cardfooter {
	width: 100%;
}

.cardfooter ion-col {
	text-align: center;
}

.cardfooter ion-icon {
	font-size: 20px;
}

@media all and (min-width: 1025px){

    .faq {
        padding: 50px;
     }

    .mat-card {
        background-color: transparent !important;
    }

    .mediaImage{
        max-width: 49%;
        display: inline-block;
        padding: 10px;
        object-fit: cover;
        height: 300px;
    }

    .podcastCard {
        width: calc((100% + 15px) / 5);
        padding-bottom: 50px;
        background-color: black;
    }

    .footerAlt {
        position: relative;
        z-index: 1;
        min-height: 20vh;
        /*background: rgb(25,0,102);
        background: linear-gradient(155deg, rgba(25,0,102,1) 0%, rgba(255,0,106,1) 100%);
        background-color: #000000;
background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23c6c6c6' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
        */
        background-color: #FAF9F6 !important;
width: 100%;
        margin-top: -100px;
        padding-top: 100px;
    }
    
    .footerInfo {
        padding: 0px;
    }

    .audioPlayer {
        position: absolute;
        width: 96%;
        height: 60px;
        bottom: 10px;
        background-color: black;
        margin-left: 20px;
        margin-right: 20px;
        padding: 5px;
        border-radius: 10px;
    }

    .brand {
        width: 275px;
    }

    #section1 {
      min-height: 80vh;
      
      /*clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%);*/
    }
  
    #section2 {
      /*clip-path: polygon(50% 10%, 100% 0, 100% 90%, 50% 100%, 0 90%, 0 0);*/
      min-height: 80vh;
      /*clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%);*/
    }
  
    #section3 {
      min-height: 80vh;
      /*clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%);*/
    }

    .sectionHeader {
        font-family: 'Raleway', sans-serif;
        color: #fff;
        font-size: 6vw;
        line-height: 1em;
        text-shadow: 4px 4px rgba(0,0,0,.2);
        margin-top: 135px;
        margin-bottom: -10px;
        font-weight: 900;
        text-transform: uppercase;
    }

    .sectionHeaderAlt {
        font-family: 'Raleway', sans-serif;
        color: #fff;
        font-size: 5.5vw;
        line-height: 1em;
        text-shadow: 4px 4px rgba(0,0,0,.2);
        margin-top: 0px;
        margin-bottom: 20px;
        font-weight: 900;
        text-transform: uppercase;
    }
    
    .sectionDescription {
        font-family: 'Raleway', sans-serif;
        color: #fff;
        font-size: 2vw;
        font-weight: 100;
        text-transform: none;
    }

    .sectionDescriptionAlt {
        font-family: 'Raleway', sans-serif;
        color: #fff;
        font-size: 2vw;
        font-weight: 100;
        text-transform: none;
    }
    
    .sectionCopy {
        max-width: 75%;
        padding-top: 80px;
        padding-left: 100px;
    }

    .sectionCopyAlt {
        max-width: 100%;
        padding-top: 80px;
        padding-left: 50px;
        padding-right: 30px;
    }

    .menuList {
        background: transparent;
        vertical-align: middle;
        margin-top: 50px;
        margin-bottom: auto;
    }
    
    .menuLabel {
        font-family: 'Raleway', sans-serif !important;
        font-size: 5vw !important;
        text-align: center;
        font-weight: 900;
    }
    
    .closeMenu {
        font-size: 2vw !important;
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 9999;
    }
  
  }

  .huh {
    width: 100% !important;
 }

 .partnersTitle {
    margin-left: 15px;
    font-weight: bold;
    font-size: 14px;
}
  
  @media all and (max-width: 1024px){
    .blogContainer swiper-slide {
        margin-top: -15px
    }
    .gridTitle {
        font-family: 'Raleway', sans-serif;
        color: #000;
        font-size: 50px;
        line-height: 1em;
        text-align: center;
        padding: 20px 0 20px 0;
        font-weight: 900 !important;
    }
    .socialIcon, .terms, .copyright {
        color: black !important;
    }
    .sectionContainer swiper-slide {
        height: 600px !important;
    }

    ion-slide {
        height: 600px !important;
    }

    .gridParagraph {
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
        font-size: 16px;
    }

    .titleType {
        font-family: Raleway;
        font-size: 20px;
        font-weight: 900;
    }

    .newsContent {
        font-size: 20px;
        padding: 30px;
     }

    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal>.swiper-pagination-bullets {
        bottom: 30px !important;
    }

    .sectionCopyAlt {
        max-width: 100%;
        padding: 20px;
    }

    .mainTitle {
        padding-left: 60px !important;
        font-size: 30px !important;
    }

    .faq {
        padding: 20px;
     }

    .audioClose, .audioBack, .audioForward {
        margin-top: -20px;
    }

    .audioBack, .audioForward {
        margin-left: 15px !important;
    }

    .audioGrid {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;
        margin-top: -2px;
    }

    .ngx-audio-player .ngx-col {
        display: none !important;
    }

    .mat-card {
        height: 92px !important;
        background: transparent !important;
        box-shadow: none !important;
    }

    .ngx-d-flex {
        padding-right: 20px !important;
        padding-top: 20px !important;
    }

    .ngx-d-none .ngx-d-sm-block .ngx-py-3 .ngx-px-1 {
        display: none !important;
    }
    
    mat-slider, .mat-slider, .ngx-flex-fill {
        display: block !important;
    }

    .mat-slider-wrapper {
        background-color: #fff !important;
    }
    
    .mat-slider-track-fill {
        background-color: #000 !important
    }
    
    .mat-button {
        min-width: 1px !important;
        background-color: transparent !important;
    }
    
    .mat-slider-thumb {
        background-color: white !important;
        bottom: -15px !important;
        height: 30px !important;
        width: 30px !important;
    }
    
    .mat-focus-indicator.ngx-basic-audio-player-button-handler.mat-button.mat-button-base {
        font-size: 14px !important;
        font-weight: bold !important;
    }
    
    .mat-card>:last-child:not(.mat-card-footer) {
        margin-right: 25px;
    }

    .mobileMap {
        margin-top: 15px;
    }

    .mediaContainer {
        padding-top: 25px;
    }

    .mediaImage{
        width: 100%;
        padding: 10px;
        object-fit: cover;
        height: 300px;
    }

    .play-pause {
        margin-top: -20px !important;
        margin-left: 10px !important;
        color: white !important;
        border-left: 0px !important;
        border-right: 0px !important;
    }

    .audioPlayer {
        position: absolute;
        width: 100%;
        height: 100px;
        bottom: 0px;
        background: rgb(25,0,102);
        background: linear-gradient(155deg, rgba(25,0,102,1) 0%, rgba(255,0,106,1) 100%);
        margin-left: 0px;
        margin-right: 0px;
        /*padding: 5px;*/
        border-radius: 0px;
    }

    ion-progress-bar {
        --background: #ffffff;
        --progress-background: #000;
      }

    .podcastCard {
        padding-bottom: 50px;
        background-color: black;
    }

    .backButton {
        margin-left: 10px;
    }
    
    .footerAlt {
        position: relative;
        z-index: 1;
        min-height: 20vh;
        /*background-color: #000000;
background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23c6c6c6' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
        */
        background-color: #FAF9F6 !important;
width: 100%;
        margin-top: -100px;
        padding-top: 100px;
    }
    
    .footerInfo {
        padding: 10px;
    }

    .menuList {
        background: transparent;
        vertical-align: middle;
        margin-top: 100px;
        margin-bottom: auto;
    }
    
    .menuLabel {
        font-family: 'Raleway', sans-serif !important;
        font-size:30px !important;
        text-align: center;
        font-weight: 900;
    }
    
    .closeMenu {
        font-size: 20px !important;
        position: absolute;
        top: 30px;
        right: 0px;
        z-index: 9999;
    }

    ion-toolbar.mainToolbar {
        /*--background: transparent !important;*/
        /*--background: linear-gradient(155deg, rgba(25,0,102,1) 0%, rgba(255,0,106,1) 100%) !important;*/
        --background: #222428 !important;
        height: 120px !important;
        padding-top: 60px !important;
    }

    .plt-android ion-title {
        display: none !important;
    }

    .plt-android.plt-hybrid ion-toolbar.mainToolbar {
        height: auto !important;
        padding-top: 0px !important;
    }

    .plt-android.plt-hybrid .brand {
        float: left !important;
        width: 38px !important;
        margin-top: 6px !important;
    }

    .plt-android.plt-hybrid .mainTitle {
        float: left;
        padding-left: 10px !important;
        margin-top: -2px !important;
    }

    .plt-android.plt-hybrid .mobileContent {
        top: 0px !important;
    }

    .plt-mobileweb ion-toolbar.mainToolbar {
        height: 70px !important;
        padding-top: 15px !important;
    }

    /*.mobileContent {
        top: -20px;
    }*/

    .brand {
        width: 35px;
        margin-left: 10px;
    }

    .headerButton {
        --color: #fff !important;
    }

    #section1 {
        padding-bottom: 80px !important;
      /*clip-path: polygon(0 0, 100% 0, 100% 96%, 50% 100%, 0 96%);*/
    }
  
    #section2 {
        padding-bottom: 80px !important;
      /*clip-path: polygon(50% 10%, 100% 0, 100% 96%, 50% 100%, 0 96%, 0 0);*/
    }
  
    #section3 {
        padding-bottom: 80px !important;
      /*clip-path: polygon(50% 10%, 100% 0, 100% 96%, 50% 100%, 0 96%, 0 0);*/
    }

    .sectionHeader, .sectionHeaderAlt {
        font-family: 'Raleway', sans-serif;
        color: #fff;
        /*font-size: 60px;*/
        line-height: 1em;
        font-size: 2.5em;
        font-weight: 900;
    }
    
    .sectionDescription {
        font-family: 'Raleway', sans-serif;
        color: #fff;
        font-size: 22px;
        font-weight: 900;
    }

    .sectionDescriptionAlt {
        font-family: 'Raleway', sans-serif;
        color: #fff;
        font-size: 22px;
        font-weight: 400;
    }

    .sectionSubtitle {
        color: #fff;
        font-size: 22px;
        font-family: Raleway;
        font-weight: 900;
    }
    
    .sectionCopy {
        max-width: 100%;
        padding-top: 60px;
        padding-left: 20px;
        padding-right: 20px;
    }
  
  }
  
  #section1 {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat; 
    background-position: center; 
    position: relative;
    z-index: 10;
  }

  #sectionPod1 {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat; 
    background-position: center; 
    position: relative;
    z-index: 10;
  }
  
  #section2 {
    margin-top: -100px;
    padding-top: 100px;
    position: relative;
    z-index: 9;
    background: rgb(25,0,102);
    background: linear-gradient(45deg, rgba(25,0,102,1) 0%, rgba(235,0,139,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat; 
    background-position: center; 
  }
  
  #section3 {
    margin-top: -100px;
    padding-top: 100px;
    position: relative;
    z-index: 8;
    /*background: rgb(25,0,102);
    background: linear-gradient(45deg, rgba(25,0,102,1) 0%, rgba(235,0,139,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat; 
    background-position: center; */
  }
  
  #outer-section1 {
    filter: drop-shadow(0px 1px 4px rgb(0, 0, 0, 0.5));
    z-index: 10;
    position: relative;
  }
  
  #outer-section2 {
    filter: drop-shadow(0px 1px 4px rgb(0, 0, 0, 0.5));
    z-index: 9;
    position: relative;
  }
  
  #outer-section3 {
    filter: drop-shadow(0px 1px 4px rgb(0, 0, 0, 0.5));
    z-index: 8;
    position: relative;
  }

  #outer-section4 {
    filter: drop-shadow(0px 1px 4px rgb(0, 0, 0, 0.5));
    z-index: 7;
    position: relative;
  }

  #outer-section5 {
    filter: drop-shadow(0px 1px 4px rgb(0, 0, 0, 0.5));
    z-index: 6;
    position: relative;
  }

  #outer-section6 {
    filter: drop-shadow(0px 1px 14px rgb(0, 0, 0, 0.5));
    z-index: 5;
    position: relative;
  }
  
  .accountButton {
    margin-left: 10px;
  }

.footer {
    position: relative;
    z-index: 1;
    min-height: 20vh;
    /*background-color: #000000;
background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23c6c6c6' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
    */
    background-color: #FAF9F6 !important;

width: 100%;
    margin-top: -100px;
    padding-top: 100px;
}

.cardFooter {
    width: 100%;
    position: absolute;
    bottom: 0px;
    padding-bottom: 10px;
}



.socials {
    text-align: right;
}

.socialsMobile {
    padding-top: 20px;
}

ion-menu {
    --min-width: 100%;
  }





.headerActionButton {
    --background: transparent;
    border: 3px solid white;
    /*border-radius: 30px;*/
    border-radius: 0px !important;
    --border-radius: 0px !important;
    --background-hover: linear-gradient(155deg, rgba(25,0,102,1) 0%, rgba(255,0,106,1) 100%) !important;
}

.actionButton {
    --background: transparent;
    border: 3px solid white;
    border-radius: 0px !important;
    --border-radius: 0px !important;
    --background-hover: linear-gradient(155deg, rgba(25,0,102,1) 0%, rgba(255,0,106,1) 100%);
}









.section1 {
    background-color: blue;
    height: 100vh;
}

.authInput .native-input{
    padding: 20px;
    border-radius: 30px;
    margin-top: 10px;
}

.authText {
    color: #fff;
}

.authGrid {
    margin-top: 20px;
}

.authItem {
    padding: 10px;
}

.newletterItem {
    --color: #fff !important;
    --background: #000 !important;
    --border-radius: 20px !important;
    margin-bottom: 30px !important;
}

.fieldCol {
    padding-right: 15px;
}

.newsletterInput {
    --placeholder-color: #fff !important;
    --color: #fff !important;
    --background: transparent !important;
    margin-bottom: 5px !important;
    padding: 0px;
    border-bottom: 1px solid white;
}

.newsletterLabel {
    margin-left: 20px !important;
    margin-top: 10px !important;
}

.newsletterButton {
    --background: transparent !important;
    margin-top: 35px !important;
}

.username {
    text-transform: capitalize !important;
  }

.footerItemLabel {
    margin-left: 15px;
}

.footerList {
    background: transparent;
    margin-bottom: 50px;
}

.footerItem {
    margin-top: 10px;
    --border-radius: 10px;
}

.footerItemTitle {
    font-size: 18px !important;
    font-weight: 900;
    font-family: 'Raleway', sans-serif;
}

.footerItemSubtitle {
    margin-top: 5px !important;
}

.podcastGrid {
    display: flex;
    align-items: stretch;
    justify-content: center;
}



.brandImage {
    max-width: 100px;
    padding: 5px;
}

.brands {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
}

.aboutFooter {
    padding-top: 40px;
}

.contactItem {
    padding-left: 0px !important;
}

.contactDescription {
    font-size: 20px !important;
}

.mediaImages {
    width: 100%;
    text-align: center;
}

.userInfo {
    padding: 50px
}

.authMessage {
    text-align: center;
    font-weight: bold;
    color: #FF006A !important;
}

/*.applyOptions {
    padding-bottom: 250px;
}*/

.applyItemTitle {
    font-size: 24px !important;
    font-weight: 900;
    font-family: 'Raleway', sans-serif;
    padding-bottom: 10px;
    padding-top: 10px;
}

.badgesTitle {
    font-size: 44px !important;
    font-weight: 900;
    font-family: 'Raleway', sans-serif;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
}

.badgesSubtitle {
    font-size: 18px !important;
    font-weight: 900;
    font-family: 'Raleway', sans-serif;
    padding-bottom: 10px;
    padding-top: 10px;
    color: white;
    text-align: center;
}

.badgesItem {
    text-align: center !important;
    --padding: 0px !important;
    padding: 0px !important;
}

.badgeList {
    text-align: center;
    color: white;
    font-size: 24px;
    padding-top: 10px;
}

.badgeButton {
    margin-top: 20px;
    margin-bottom: 20px;
}

.badgeList .buttonText {
    font-family: Raleway;
    font-weight: 900;
}

.applyCol {
    display: flex;
}

.faq a {
    color: white !important;
    font-weight: bold !important;
    text-decoration: underline;
}



.terms {
    font-weight: bold;
    text-decoration: underline;
    color: white;
}

.sectionList {
    margin-bottom: 20px;
}

.ticketItem {
    --padding-start: 0px !important;
    --padding-top: 0px !important;
    margin-bottom: 0px;
    font-weight: 500;
}

.ticketItem .buttonText {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 900;
}

.firstTicketItem {
    margin-top: 25px;
}

.section {
    background-size: cover;
      background-repeat: no-repeat;
}

.editPages {
    margin-top: 10px;
    text-transform: capitalize;
}

.updateEditIcon {
    margin-top: 20px;
}

ion-modal.editModal::part(content) {    
    --height: 100%;
    position: absolute; 
    top: 0%;
    --width: 100%;
    border-radius: 0px;
    display: block;
 }

 .subMenu {
    /*--background: linear-gradient(155deg, rgba(25,0,102,1) 0%, rgba(255,0,106,1) 100%) !important;*/
    --background: #121212 !important;
 }

 .submenuItem {
    font-family: Raleway;
    font-weight: 900;
 }

 .submenuItem:hover {
    border: 2px white solid !important;
    
 }

 .menuPopover {
    border-radius: 0px !important;
    margin-top: 10px !important;
 }

 .popover-open {
    pointer-events: none !important;
 }

 .popover-backdrop {
    height: 500px !important;
    top: 200px !important;
 }

 .finePrint {
    font-style: italic;
    font-size: 12px;
 }

 .deadline {
    font-size: 20px;
 }

 .headerPrefix {
    font-weight: bold !important;
 }

 .blogFeed {
    margin-top: 20px !important;
 }

 .blogGrid, .blogRow, .blogCol, .blogCard {
    padding-left: 0px !important;
    margin-left: 0px !important;
    display: flex;
    align-items: stretch;
    justify-content: center;
 }

 .blogAction {
    margin-bottom: 50px !important;
 }

 .itemText {
    margin-left: 10px;
    font-size: 20px !important;
 }

 .desktopNewsContainer {
    padding-bottom: 200px;
 }

 

 .error {
    padding: 0px 30px;
    color: red;
}

.homeSlide, .homeSlider, .sectionDescription {
    text-align: left !important;
}

.homeSlider {
    --swiper-pagination-bullet-inactive-color: #ffffff;
    --swiper-pagination-color: #eb008b;
    --swiper-navigation-color: #ffffff;
}

swiper-slide {
    display: flex;
    position: relative;
  
    flex-shrink: 0;
    align-items: center;
    justify-content: left;
  
    width: 100%;
    height: 100%;
  
    font-size: 18px;
  
    text-align: left;
    box-sizing: border-box;
  }
  
  swiper-slide img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }

.finePrint {
    font-size: 12px;
    font-style: italic;
    margin-top: 30px;
}

.swiper-slide {
    justify-content: left !important;
}

.musicLogo {
    margin: 0 auto !important;
}

.concertItem {
    --padding-start: 0px;
}

.motherfuckerWhat {
    background: #fff !important;
    margin-right: 20px;
    --background: #ffffff !important;
    --color: #000 !important;
    --color-hover: #fff !important;
    border: 3px solid white;
    border-radius: 0px !important;
    --border-radius: 0px !important;
    --background-hover: linear-gradient(155deg, rgba(25,0,102,1) 0%, rgba(255,0,106,1) 100%) !important;
}

.motherfuckerWhat:hover {
    background: linear-gradient(155deg, rgba(25,0,102,1) 0%, rgba(255,0,106,1) 100%) !important;
}

.secondaryAction {
    margin-right: 20px;
    --background: #ffffff !important;
    --color: #000 !important;
    --color-hover: #fff !important;
    border: 3px solid white;
    border-radius: 0px !important;
    --border-radius: 0px !important;
    --background-hover: linear-gradient(155deg, rgba(25,0,102,1) 0%, rgba(255,0,106,1) 100%);
}

.eventSection {
    padding-bottom: 175px;
}

.gridParagraph {
    font-family: Raleway;
    font-weight: 400;
    font-size: 36px;
    text-align: center;
}

.headlinerSubtitle {
    color: white;
    font-size: 18px;
}